import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				SSS | RoadCrafters Sürüş Akademisine
			</title>
			<meta name={"description"} content={"Cevaplarınızı Bulun, Yolculuğunuza Yakıt Katın!"} />
			<meta property={"og:title"} content={"SSS | RoadCrafters Sürüş Akademisine"} />
			<meta property={"og:description"} content={"Cevaplarınızı Bulun, Yolculuğunuza Yakıt Katın!"} />
			<meta property={"og:image"} content={"https://wovado.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://wovado.com/img/3381772.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://wovado.com/img/3381772.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://wovado.com/img/3381772.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://wovado.com/img/3381772.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://wovado.com/img/3381772.png"} />
			<meta name={"msapplication-TileImage"} content={"https://wovado.com/img/3381772.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" >
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
					Sıkça Sorulan Sorular
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						Mükemmel sürüşe giden yolda sorular da beraberinde gelir ve biz cevapları sunmak için buradayız. SSS sayfamız, RoadCrafters Sürüş Akademisi'nde aldığımız en yaygın sorulardan bazılarını ele almak üzere tasarlanmıştır. İster ilk kez sürücü olun, ister sürüş becerilerinizi geliştirmek isteyin, yolunuza güvenle devam etmek için ihtiyaç duyduğunuz rehberliği bulacağınızı umuyoruz.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
						RoadCrafters Sürüş Akademisine nasıl kayıt olabilirim?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Kayıt olmak çok kolay! Sürüş hedeflerinizi tartışmak için telefon veya e-posta yoluyla bizimle iletişime geçin, kayıt süreci ve kurs seçimi konusunda size rehberlik edelim.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
						Ne tür sürüş kursları sunuyorsunuz?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Acemi Sürüş, Defansif Sürüş, İleri Sürüş Teknikleri, Eko-Sürüş ve Kıdemli Tazeleme Kursları dahil olmak üzere çeşitli kurslar sunuyoruz. Her biri farklı beceri seviyelerine ve sürüş ihtiyaçlarına hitap edecek şekilde tasarlanmıştır.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
						Yoğun programıma göre sürüş dersleri planlayabilir miyim?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Kesinlikle! Yaşam tarzınıza uyum sağlamak için esnek programlama seçenekleri sunuyoruz. Sizin için neyin uygun olduğunu bize bildirin, biz de müsaitlik durumunuza uymak için elimizden geleni yapalım.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
						Sürüş dersleri için kendi arabama ihtiyacım var mı?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Hayır, gerekmez. RoadCrafters, tüm yol eğitimleri için modern, güvenlik donanımlı araçlardan oluşan bir filo sağlamaktadır. Güvenli ve etkili bir öğrenme deneyimi için tasarlanmış araçlarımızda öğrenebilirsiniz.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
						Bir sürüş kursunu tamamlamak ne kadar sürer?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Kurs süresi programa bağlı olarak değişir. Kurs süreleri hakkında ayrıntılı bilgi için lütfen doğrudan bizimle iletişime geçin.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
						İptal politikanız nedir?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Planların değişebileceğini biliyoruz. Önceden bildirimde bulunarak dersleri yeniden planlamanıza olanak tanıyan esnek bir iptal politikası sunuyoruz. Daha detaylı bilgi için lütfen bize ulaşın.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
						Eğitmenleriniz sertifikalı mı?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Evet, tüm eğitmenlerimiz sürücü eğitiminde geniş deneyime sahip sertifikalı profesyonellerdir. Öğretme konusunda tutkuludurlar ve kendinizi başarınıza adamışlardır.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
						Sürüş dersleri sırasında güvenliği sağlamak için ne gibi önlemler alıyorsunuz?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Güvenlik bizim en önemli önceliğimizdir. Araçlarımız en son güvenlik özellikleriyle donatılmıştır ve tüm dersler kapsamlı güvenlik brifinglerini içerir. Eğitmenlerimiz ayrıca sıkı sağlık ve güvenlik protokollerini takip etmektedir.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Hala Sorularınız mı Var?
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Sorunuz burada yanıtlanmadıysa, bize ulaşmaktan çekinmeyin. Ekibimiz her türlü sorunuzda size yardımcı olmaya her zaman hazırdır ve sürüş yolculuğunuza çıkarken kendinizi tam olarak bilgilendirilmiş ve güvende hissetmenizi sağlar. İstediğiniz zaman telefon veya e-posta yoluyla bize ulaşın ve sürüş eğitiminizi doğru yönde yönlendirelim.
					</Text>
					<Button
						background="#0438cd"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Kişiler
					</Button>
				</Box>
				<Image src="https://wovado.com/img/8.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});